import cn from '@/lib/cn';

export const highlightedTextClassName =
    'bg-gradient-to-r from-thematic-purple to-thematic-blue bg-clip-text text-transparent';
export const highlightedTextClassNameOnHover =
    'hover:bg-gradient-to-r hover:from-thematic-purple hover:to-thematic-blue hover:bg-clip-text hover:text-transparent transition-all';

const HighlightedText = ({ children, className }: { children: React.ReactNode; className?: string }) => (
    <span
        className={cn(
            'bg-gradient-to-r from-thematic-purple to-thematic-blue bg-clip-text text-transparent',
            className
        )}
    >
        {children}
    </span>
);

export default HighlightedText;
