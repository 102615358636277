import { Nullable } from '@/types/nullable';

export enum ScrollDirection {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

export interface ScrollIntoViewWithOffsetArgs {
    rootElem?: Nullable<HTMLElement | (Window & typeof globalThis)>;
    scrollToElement?: Nullable<HTMLElement>;
    offsetPosition?: number;
    callback?: () => void;
    scrollDirection?: ScrollDirection;
    scrollToEnd?: boolean;
    positionElementInMiddle?: boolean;
    scrollToTop?: boolean;
}

interface GetScrollToVerticalPositionArgs {
    offsetPosition: number;
    positionElementInMiddle?: boolean;
    scrollToEnd?: boolean;
    scrollToElement: HTMLElement;
    rootElem: HTMLElement | (Window & typeof globalThis);
}

type ScrollToArgs = GetScrollToVerticalPositionArgs & {
    scrollDirection: ScrollDirection;
};

const getScrollToVerticalPosition = ({
    offsetPosition,
    positionElementInMiddle = false,
    scrollToEnd = false,
    scrollToElement,
    rootElem,
}: GetScrollToVerticalPositionArgs) => {
    if (positionElementInMiddle) {
        const elementRect = scrollToElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const targetPosition = elementRect.top - windowHeight / 2 + elementRect.height / 2 + window.scrollY;

        return { top: targetPosition };
    }

    const elementPosition = scrollToElement?.getBoundingClientRect().top || 0;
    const offsetValue = elementPosition + window.scrollY - offsetPosition;
    const endPos = rootElem instanceof Window ? document.documentElement.scrollHeight : rootElem.scrollHeight;
    return { top: scrollToEnd ? endPos : offsetValue };
};
const getScrollToArgs = ({
    rootElem,
    scrollToElement,
    scrollDirection,
    offsetPosition,
    scrollToEnd = false,
    positionElementInMiddle = false,
}: ScrollToArgs) => {
    if (rootElem && scrollToElement) {
        if (scrollDirection === ScrollDirection.VERTICAL) {
            return getScrollToVerticalPosition({
                offsetPosition,
                positionElementInMiddle,
                rootElem,
                scrollToElement,
                scrollToEnd,
            });
        }

        // offsetLeft allows you to get position relative to it's parent
        // container rather than the overall window.
        const elementPosition = scrollToElement?.offsetLeft || 0;
        const offsetValue = elementPosition + window.scrollX - offsetPosition;
        const endPos = rootElem instanceof Window ? document.documentElement.scrollWidth : rootElem.scrollWidth;

        return { left: scrollToEnd ? endPos : offsetValue };
    }
};

export default function scrollIntoViewWithOffset({
    scrollToElement,
    offsetPosition = 0,
    callback,
    rootElem = window,
    scrollDirection = ScrollDirection.VERTICAL,
    scrollToEnd = false,
    positionElementInMiddle,
}: ScrollIntoViewWithOffsetArgs) {
    if (scrollToElement && rootElem) {
        const scrollToArgs = getScrollToArgs({
            offsetPosition,
            positionElementInMiddle,
            rootElem,
            scrollDirection,
            scrollToElement,
            scrollToEnd,
        });

        rootElem.scrollTo({
            behavior: 'smooth',
            ...scrollToArgs,
        });

        callback?.();
    }
}
