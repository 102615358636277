import { MouseEvent, TouchEventHandler, useCallback, useEffect, useState } from 'react';

import scrollIntoViewWithOffset, { type ScrollIntoViewWithOffsetArgs } from '@/helpers/scrollIntoViewWithOffset';
import { Nullable } from '@/types/nullable';

const useSmoothScrollTo = ({
    rootElem,
    scrollToElement,
    offsetPosition = 0,
    callback,
    positionElementInMiddle = false,
    scrollToTop = false,
    scrollToEnd = false,
}: ScrollIntoViewWithOffsetArgs) => {
    const [windowElement, setWindowElement] = useState<Nullable<Window>>(null);
    const halfViewportHeight = windowElement?.innerHeight || 0 / 2;
    const { height = 0 } = scrollToElement?.getBoundingClientRect() || {};
    const scrollToElementHalfwayPoint = halfViewportHeight - height / 2;
    const offset = positionElementInMiddle ? scrollToElementHalfwayPoint : offsetPosition;
    const onClick = useCallback(
        (evt?: MouseEvent<HTMLButtonElement>) => {
            evt?.preventDefault();
            if (scrollToElement && !scrollToTop) {
                scrollIntoViewWithOffset({
                    callback,
                    offsetPosition: offset,
                    positionElementInMiddle,
                    rootElem,
                    scrollToElement,
                    scrollToEnd,
                });
            }
        },
        [callback, offset, positionElementInMiddle, rootElem, scrollToElement, scrollToEnd, scrollToTop]
    );

    const scrollToTopClick = (evt?: MouseEvent<HTMLButtonElement>) => {
        evt?.preventDefault();
        windowElement?.scrollTo({ behavior: 'smooth', top: 0 });
    };

    const onTouchEnd: TouchEventHandler<HTMLButtonElement> = (evt) => {
        evt.preventDefault();
        if (scrollToElement && !scrollToTop) {
            scrollIntoViewWithOffset({
                callback,
                offsetPosition: offset,
                positionElementInMiddle,
                rootElem,
                scrollToElement,
                scrollToEnd,
            });
        }
    };

    useEffect(() => {
        setWindowElement(window);
    }, []);

    return { onClick, onTouchEnd, scrollToTop: scrollToTopClick };
};

export default useSmoothScrollTo;
