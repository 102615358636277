'use client';

// import { usePathname } from 'next/navigation';
import React, { createContext, RefObject, useContext, useRef, useState } from 'react';

import { AnalystProvider } from '@/components/analyst/analyst-provider';
import { CurrentOrganizationSelector } from '@/components/global/current-organization-selector';
import { GlobalNavigationBar } from '@/components/global/global-navigation-bar';
import MainFooter from '@/components/global/main-footer';
import { IndexBuilderContextProvider } from '@/components/index-builder/useIndexBuilderContext';

interface MainContentContextValue {
    intersectionRef: RefObject<HTMLElement>;
    updateIntersectionRef: (ref: RefObject<HTMLElement>) => void;
}

export const MainContentContext = createContext({} as MainContentContextValue);

export const MainContentProvider = ({ children }: { children: React.ReactNode }) => {
    const startRef = useRef<HTMLDivElement>(null);
    const [intersectionRef, setIntersectionRef] = useState<RefObject<HTMLElement>>(startRef);
    const updateIntersectionRef = (ref: RefObject<HTMLElement>) => setIntersectionRef(ref);
    const value: MainContentContextValue = { intersectionRef, updateIntersectionRef };

    return (
        <MainContentContext.Provider value={value}>
            {children}
            <div ref={startRef} />
        </MainContentContext.Provider>
    );
};

export const useMainContentContext = () => {
    const context = useContext(MainContentContext);

    if (!context) {
        throw new Error('useMainContentContext must be used within a MainContentProvider');
    }

    return context;
};

export const MainContent = ({ children }: { children: React.ReactNode }) => {
    return (
        <AnalystProvider>
            <IndexBuilderContextProvider rawBuilderIndexId="create">
                <div className="flex flex-col min-h-full">
                    <CurrentOrganizationSelector />
                    <GlobalNavigationBar />

                    {children}

                    <MainFooter className="mt-auto" />

                    <div id="root" />
                </div>
            </IndexBuilderContextProvider>
        </AnalystProvider>
    );
};
