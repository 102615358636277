import { FloationActionKeys } from '@/components/analyst/floating-actions';
import { type IconMap } from '@/components/global/icon';
import { ShareType } from '@/types/share';

export enum EventTypes {
    LOGIN_SUCCESS = 'user_login_success',
    LOGIN_ERROR = 'user_login_error',
    SIGNUP_SUCCESS = 'user_signup_success',
    SIGNUP_ERROR = 'user_signup_error',
    OTP = 'otp_verification',
    OTP_ERROR = 'otp_verification_error',
    BUILDER_CREATE = 'builder_create',
    BUILDER_UPDATE = 'builder_update',
    SHOW_MODAL = 'show_modal',
    HIDE_MODAL = 'hide_modal',
    OPEN_DROPDOWN_MENU = 'open_dropdown_menu',
    CLOSE_DROPDOWN_MENU = 'close_dropdown_menu',
    MINIMIZE_MODULE = 'minimize_module',
    EXPAND_MODULE = 'expand_module',
    NEXT_TOGGLE_CAROUSEL = 'next_toggle_carousel',
    PREV_TOGGLE_CAROUSEL = 'prev_toggle_carousel',
    TAB_CLICK = 'tab_click',
    SHOW_KEY_TAKEAWAYS = 'show_key_takeaways',
    HIDE_KEY_TAKEAWAYS = 'hide_key_takeaways',
    SHOW_PROMPT_FILTERS = 'show_prompt_filters',
    HIDE_PROMPT_FILTERS = 'hide_prompt_filters',
    SELECT_PROMPT_FILTER = 'select_prompt_filter',
    SELECT_USER_DROPDOWN_ITEM = 'select_user_dropdown_item',
    COMPANY_EVALUATION_CARD_CLICK = 'company_evaluation_card_click',
    FETCH_COMPANY_EVALUATION_RESULT = 'fetch_company_evaluation_result',
    POLLING_FOR_COMPANY_EVALUATION_RESULT = 'polling_for_company_evaluation_result',
    FETCH_COMPANY_EVALUATION_REQUEST_ID = 'fetch_company_evaluation_request_id',
    COMPANY_EVALUATION_REQUEST_ID_RECEIVED = 'company_evaluation_request_id_received',
    COMPANY_EVALUATION_RESULT_RECEIVED = 'company_evaluation_result_received',
    SCROLL_TO_SECTION = 'scroll_to_section',
    PROMPT_SUBMIT = 'prompt_submit',
    PROMPT_FILTER_ADD = 'prompt_filter_add',
    PROMPT_FILTER_APPLIED = 'prompt_filter_applied',
    COMPANY_SEARCH = 'company_search',
    INTERNAL_LINK_CLICK = 'internal_link_click',
    EXTERNAL_LINK_CLICK = 'external_link_click',
    OPEN_MAIN_SIDEBAR_NAV = 'open_main_sidebar_nav',
    CLOSE_MAIN_SIDEBAR_NAV = 'close_main_sidebar_nav',
    RESEND_OTP = 'resend_otp',
    RESEND_OTP_SUCCESS = 'resend_otp_success',
    RESEND_OTP_ERROR = 'resend_otp_error',
    SHOW_LOGIN_FORM = 'show_login_form',
    SHOW_SIGNUP_FORM = 'show_signup_form',
    SHOW_OTP_FORM = 'show_otp_form',
    SIGNUP_FORM_SUBMIT = 'signup_form_submit',
    LOGIN_FORM_SUBMIT = 'login_form_submit',
    OTP_FORM_SUBMIT = 'otp_form_submit',
    DOWNLOAD_CSV = 'download_csv',
    DOWNLOAD_PERFORMANCE_CSV = 'download_performance_csv',
    RESEARCH_PROMPT_QUERY_RUN = 'research_prompt_query_run',
    INSTRUMENT_ANALYSIS_PROMPT_QUERY_RUN = 'instrument_analysis_prompt_query_run',
    PROMPT_UPDATED = 'prompt_updated',
    PROMPT_QUERY_COMPLETED = 'prompt_query_completed',
    SELECT_EXAMPLE_PROMPT = 'select_example_prompt',
    ADD_TO_PORTFOLIO = 'add_to_portfolio',
    EXPORT_INSTRUMENT = 'export_instrument',
    EXPORT_TEARSHEET = 'export_tearsheet',
    REMOVE_FROM_RESULTS = 'remove_from_results',
    BACKTEST_AND_BENCHMARK = 'backtest_and_benchmark',
    SHARE = 'share',
    FLOATING_ACTIONS_CLICK = 'floating_actions_click',
    OPEN_FLOATING_ACTIONS_MENU = 'open_floating_actions_menu',
    CLOSE_FLOATING_ACTIONS_MENU = 'close_floating_actions_menu',
    CREATE_NEW_INDEX_SUCCESS = 'create_new_index_success',
    CREATE_NEW_INDEX_ERROR = 'create_new_index_error',
    EXISTING_INDEX_UPDATED = 'existing_index_updated',
    CREATE_INDEX_DRAFT = 'create_index_draft',
    CREATE_INDEX_DRAFT_ERROR = 'create_index_draft_error',
    PUBLISHING_INDEX = 'publishing_index',
    PUBLISH_INDEX_SUCCESS = 'publish_index_success',
    PUBLISH_INDEX_ERROR = 'publish_index_error',
    COPY_LINK = 'copy_link',
    SIGNUP_LOGIN = 'signup_login',
    COMPARE_INDICES = 'compare_indices',
    BROWSE_EVALUATIONS = 'browse_evaluations',
    SEARCH_FOR_COMPANY = 'search_for_company',
    CONFIRM = 'confirm',
    LETTER_SEARCH_FILTER_CLICKED = 'letter_search_filter_clicked',
    UNFOLLOW_INDEX = 'unfollow_index',
    UNFOLLOW_INDEX_SUCCESS = 'unfollow_index_success',
    UNFOLLOW_INDEX_ERROR = 'unfollow_index_error',
    FOLLOW_INDEX = 'follow_index',
    FOLLOW_INDEX_SUCCESS = 'follow_index_success',
    FOLLOW_INDEX_ERROR = 'follow_index_error',
    SHOW_BENCHMARK_ERRORS = 'show_benchmark_errors',
    RENAME_STRATEGY = 'rename_strategy',
    CANCEL_RENAME_STRATEGY = 'cancel_rename_strategy',
    CONFIRM_RENAME_STRATEGY = 'confirm_rename_strategy',
    SAVE_RENAME_STRATEGY_CHANGES = 'save_rename_strategy_changes',
    SHOW_SECURITY_INFO = 'show_security_info',
    CHANGE_PROFILE_IMAGE = 'change_profile_image',
    REMOVE_PROFILE_IMAGE = 'remove_profile_image',
    SAVE_PROFILE_IMAGE = 'save_profile_image',
    NAVIGATION_LINK_CLICK = 'navigation_link_click',
    BUTTON_CLICK = 'button_click',
    ACTION_LINK_CLICK = 'action_link_click',
    SHOW_COLLAPSIBLE_SECTION = 'show_collapsible_section',
    HIDE_COLLAPSIBLE_SECTION = 'hide_collapsible_section',
    STICKY_SECTION_NAV_CLICK = 'sticky_section_nav_click',
    SCROLL_TO_BUTTON_CLICK = 'scroll_to_button_click',
    SUBMIT_BUTTON_CLICK = 'submit_button_click',
    SHOW_ARTICLE_REFERENCES = 'show_article_references',
    HIDE_ARTICLE_REFERENCES = 'hide_article_references',
    RESOURCE_NAVIGATION_LINK_CLICK = 'resource_navigation_link_click',
    ADD_PROMPT_FILTER = 'add_prompt_filter',
    GOOGLE_SSO_SIGNUP_SUCCESS = 'google_sso_signup_success',
    GOOGLE_SSO_LOGIN_SUCCESS = 'google_sso_login_success',
    GOOGLE_SSO_SUCCESS = 'google_sso_success',
    GOOGLE_SSO_ERROR = 'google_sso_error',
    SCROLL_TO_USE_CASE_SECTION = 'scroll_to_use_case_section',
    VIEW_LIVE_RESULTS_IMAGE_LINK_CLICK = 'view_live_results_image_link_click',
    FLOW_CHART_STEP_CLICK = 'flow_chart_step_click',
}

export enum ComponentTypes {
    BUTTON = 'button',
    INTERNAL_BUTTON_LINK = 'internal_button_link',
    EXTERNAL_BUTTON_LINK = 'external_button_link',
    INTERNAL_NAVIGATION_LINK = 'internal_navigation_link',
    EXTERNAL_NAVIGATION_LINK = 'external_navigation_link',
    TEXT_FIELD = 'text_field',
    BASE_PROMPT_FIELD = 'base_prompt_field',
    PROMPT_FIELD = 'prompt_field',
    DROPDOWN_MENU_TOGGLE = 'dropdown_menu_toggle',
    DROPDOWN_MENU_ITEM = 'dropdown_menu_item',
    USER_PROFILE_DROPDOWN_MENU = 'user_profile_dropdown_menu',
    MOBILE_HAMBURGER_MENU_TOGGLE = 'mobile_hamburger_menu_toggle',
    MOBILE_CLOSE_MENU_BUTTON = 'mobile_close_menu_button',
    THEMATIC_LOGO_LINK = 'thematic_logo_link',
    GLOBAL_NAV_MAIN_LINK = 'global_nav_main_link',
    GLOBAL_NAV_SUB_MENU = 'global_nav_sub_menu',
    ACCORDION_MENU_TOGGLE = 'accordion_menu_toggle',
    ACCORDION_MENU_ITEM = 'accordion_menu_item',
    SCROLL_TO_ARROW_BUTTON = 'scroll_to_arrow_button',
    USER_PROFILE_DROPDOWN_TOGGLE = 'user_profile_dropdown_toggle',
    LOGIN_SIGNUP_MODAL = 'login_signup_modal',
    LOGIN_SIGNUP_MODAL_BUTTON = 'login_signup_modal_button',
    SIGNUP_FORM = 'signup_form',
    LOGIN_FORM = 'login_form',
    OTP_FORM = 'otp_form',
    MODAL_CLOSE_BUTTON = 'modal_close_button',
    CONFIRM_OVERRIDE_BUILDER_PORTFOLIO_MODAL = 'confirm_override_builder_portfolio_modal',
    INDEX_COMPARISON_MODAL = 'index_comparison_modal',
    COMPARE_SELECTED_INDICES_BUTTON = 'compare_selected_indices_button',
    FEATURED_BUTTON = 'featured_button',
    FEATURED_LINK_BUTTON = 'featured_link_button',
    EXAMPLE_PROMPT = 'example_prompt',
    COMPANY_SEARCH_FIELD = 'company_search_field',
    SUBMIT_BUTTON = 'submit_button',
    SHARE_MODAL = 'share_modal',
    COPY_EMBED_GRAPH_BUTTON = 'copy_embed_graph_button',
    CHOOSE_BENCHMARKS_MODAL = 'choose_benchmarks_modal',
    OUR_REASONING_KEY_TAKEAWAYS_COLLAPSIBLE_SECTION = 'our_reasoning_key_takeaways_collapsible_section',
    VIEW_LIVE_RESULTS_IMAGE_LINK = 'view_live_results_image_link',
    TRY_IT_YOURSELF_BUTTON = 'try_it_yourself_button',
}

export enum Categories {
    BUILDER = 'builder',
    COMPANY_INFO = 'company_info',
    COMPANY_EVALUATION = 'company_evaluation',
    RESEARCH_ANALYSIS = 'research_analysis',
    INSTRUMENT_ANALYSIS = 'instrument_analysis',
    COMPANY_SEARCH = 'company_search',
    FLOATING_ACTIONS = 'floating_actions',
    LINK_CLICK = 'link_click',
    BUTTON_CLICK = 'button_click',
    INPUT_CHANGE = 'input_change',
    MODULE_ACTION = 'module_action',
    DROPDOWN_MENU_ACTION = 'dropdown_menu_action',
    FORM_SUBMISSION = 'form_submission',
    LOGIN_SIGNUP = 'login_signup',
    NAVIGATION_LINK = 'navigation_link',
    PLATFORM = 'platform',
    MARKETING = 'marketing',
    USER_TEAM_MANAGEMENT = 'user_team_management',
    SHARE = 'share',
    INDEX = 'index',
}

export enum BuilderSteps {
    START = 'start',
    SELECT = 'select',
    BUILD = 'build',
    PUBLISH = 'publish',
}

export enum ModalTypes {
    LOGIN = 'login',
    SIGNUP = 'signup',
    SHARE = 'share',
    OTP = 'otp',
    RECENT_EVALUATION = 'recent_evaluation',
    INDEX_COMPARISON = 'index_comparison',
    CONFIRM_OVERRIDE_BUILDER_PORTFOLIO = 'confirm_override_builder_portfolio',
    INDEX_SHARE = 'index_share',
    LOGIN_SIGNUP = 'login_signup',
    CONFIRM = 'confirm',
    CHOOSE_BENCHMARKS = 'choose_benchmarks',
    BENCHMARK_ERRORS = 'benchmark_errors',
    EDIT_STRATEGY = 'edit_strategy',
    SECURITY_INFO = 'security_info',
    PROFILE_IMAGE = 'profile_image',
    PRESERVE_PROMPT = 'preserve_prompt',
}

export enum ModuleTypes {
    ALL = 'all',
    KEY_TAKEAWAYS = 'keyTakeaways',
    COMPANY_ANALYSIS = 'companyAnalysis',
    COMPANY_EVALUATION_ANALYSIS = 'companyEvaluationAnalysis',
    PEER_FUNDAMENTALS = 'peer_fundamentals',
    PRICE_HISTORY = 'price_history',
    ASK_A_QUESTION = 'ask_a_question',
    DOCUMENTS = 'documents',
    FILTERS = 'filters',
    EVALUATION_SUMMARY = 'evaluation_summary',
    FUNDAMENTALS = 'fundamentals',
    COMPARATIVE_ANALYSIS = 'comparative_analysis',
    PUBLIC_FILINGS = 'public_filings',
    EARNINGS_REPORTS = 'earnings_reports',
    FINANCIAL_STATEMENTS = 'financial_statements',
    COMPANY_PERFORMANCE = 'company_performance',
    DESCRIPTION = 'description',
    METRICS = 'metrics',
    NEWS = 'news',
}

export enum InputTypes {
    SELECT = 'select',
    TEXT = 'text',
    TEXTAREA = 'textarea',
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    MULTI_SELECT = 'multi_select',
}

export type EventType = `${EventTypes}`;

export type BaseTrackingProperties = {
    id?: string;
    label?: React.ReactNode;
    component?: `${ComponentTypes}`; // eg. global nav -> Research submenu
    section?: string; // eg. Hero Section
    additionalTrackingProperties?: Record<string, unknown>;
    category?: `${Categories}`;
    actionType?: `${EventTypes}`;
};

export type NavLinkWithTextLabel = BaseTrackingProperties & {
    href: string;
};

export type NavLinkWithIconLogo = BaseTrackingProperties & {
    href: string;
    iconLogo: string;
};

export type NavigationLinkClickTrackingProperties = BaseTrackingProperties & {
    href: string;
    iconLogo?: string;
};

export type ActionLinkClickTrackingProperties = BaseTrackingProperties & {
    href: never;
};

export type ButtonClickTrackingProperties = BaseTrackingProperties & {
    iconType?: keyof typeof IconMap;
};

export type ModalTrackingProperties = BaseTrackingProperties & {
    modalType: `${ModalTypes}`;
};

export type ResearchAnalysisTrackingProperties = BaseTrackingProperties & {
    requestId: string;
    queryType?: string;
    prompt: string;
};

export type ResearchPromptResultTrackingProperties = BaseTrackingProperties & {
    requestId: string;
    themeName: string;
    prompt: string;
    relatedInstrumentIds?: Array<string>;
};

export type InstrumentAnalysisTrackingProperties = BaseTrackingProperties & {
    searchQuery: string;
    companyName: string;
    exchange: string;
    queryType: string;
    symbol: string;
};

export type BasePromptTrackingProperties = BaseTrackingProperties & {
    filterType?: string;
    filterValue?: string;
};

export type CompanySearchTrackingProperties = BaseTrackingProperties & {
    resultsCount: number;
    searchQuery: string;
};

export type FloatingActionsTrackingProperties = BaseTrackingProperties & {
    actionType: `${FloationActionKeys}`;
};

export type CompanyInfoTrackingProperties = BaseTrackingProperties & {
    companyName: string;
    exchange: string;
    symbol: string;
    moduleType?: `${ModuleTypes}`;
};

export type CompanyEvaluationTrackingProperties = CompanyInfoTrackingProperties & {
    requestId?: string;
};

export type IndexBuilderTrackingProperties = BaseTrackingProperties & {
    builderIndexId?: string;
    symbol: string;
    name: string;
    organizationId: string;
    isHidden: boolean;
    isPortfolio: boolean;
    originatingAnalystThemeRequestId?: string;
    benchmarkPublicIndexIds?: Array<string>;
    indexId: string;
    step: `${BuilderSteps}`;
};

export type ErrorTrackingProperties = BaseTrackingProperties & {
    field?: string;
    message: string;
    errorType?: string;
} & Partial<IndexBuilderTrackingProperties>;

export type SignupLoginOTPTrackingProperties = BaseTrackingProperties & {
    loginAttemptId?: string;
    email?: string;
    name?: string;
    userId?: string;
    phone?: string;
    otp?: string;
};

export type IndexTrackingProperties = BaseTrackingProperties & {
    symbol: string;
    companyName: string;
    exchange?: string;
    id: string;
};

export type ShareTrackingProperties = BaseTrackingProperties & {
    shareType: `${ShareType}`;
};

export type AllTrackingPropertiesTypes =
    | BaseTrackingProperties
    | NavigationLinkClickTrackingProperties
    | ActionLinkClickTrackingProperties
    | ButtonClickTrackingProperties
    | ModalTrackingProperties
    | ResearchAnalysisTrackingProperties
    | ResearchPromptResultTrackingProperties
    | InstrumentAnalysisTrackingProperties
    | CompanySearchTrackingProperties
    | FloatingActionsTrackingProperties
    | CompanyInfoTrackingProperties
    | CompanyEvaluationTrackingProperties
    | IndexBuilderTrackingProperties
    | ErrorTrackingProperties
    | SignupLoginOTPTrackingProperties
    | ShareTrackingProperties
    | BasePromptTrackingProperties;

export enum ComponentLocations {
    GLOBAL_NAV = 'global_nav',
    MOBILE_SIDEBAR_NAV = 'mobile_sidebar_nav',
    HERO_SECTION = 'hero_section',
    GLOBAL_FOOTER = 'global_footer',
    LOGIN_SIGNUP_MODAL = 'login_signup_modal',
    DASHBOARD_HEADER = 'dashboard_header',
    COMPANY_PAGE_PROMPT_SEARCH_HEADER = 'company_page_prompt_search_header',
    ANALYST_PAGE_HEADER = 'analyst_page_header',
    PLATFORM_START_RESEARCH_TAB_VIEW = 'platform_start_research_tab_view',
    PLATFORM_START_FIND_COMPANY_TAB_VIEW = 'platform_start_find_company_tab_view',
    PLATFORM_START_STOCK_SCREENER_TAB_VIEW = 'platform_start_stock_screener_tab_view',
    PRODUCT_MAIN_HEADER = 'product_main_header',
    TRY_IT_YOURSELF_SECTION = 'try_it_yourself_section',
    FLOATING_ACTIONS_MENU = 'floating_actions_menu',
    PLATFORM_START_TAB_VIEW = 'platform_start_tab_view',
    WORKFLOW_SECTION = 'workflow_section',
    SHARE_MODAL = 'share_modal',
    INDEX_TABLE = 'index_table',
    INDEX_SHARE_MODAL = 'index_share_modal',
    RECENT_EVALUATIONS_MODAL = 'recent_evaluations_modal',
    COMPANY_PAGE_HEADER = 'company_page_header',
    COMPANY_EVALUTION_STICKY_SECTION_NAV = 'company_evaluation_sticky_section_nav',
}
