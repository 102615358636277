'use client';

import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import React, { forwardRef, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import CirlceEyeIcon from '@/assets/icons/double-circle-eye.svg';
import CurvedBarGraphIcon from '@/assets/icons/empty-curved-bar-graph.svg';
import SpiralIcon from '@/assets/icons/empty-spiral.svg';
import IALogo from '@/assets/logos/intelligent-alpha-logo-reverse.svg';
import PinkChipLogo from '@/assets/logos/pink-chip-logo.svg';
import WGALogo from '@/assets/logos/wga-logo.svg';
import { Heading, Paragraph } from '@/components/dom/text-elements';
import Button from '@/components/global/button';
import CheckmarkPill from '@/components/global/CheckmarkPill';
import { HeroSectionWrapper } from '@/components/global/HeroSectionWrapper';
import { NavigationLink } from '@/components/global/link';
import SectionWrapper from '@/components/global/SectionWrapper';
import ThematicPill from '@/components/global/ThematicPill';
import TryItYourselfSection from '@/components/global/TryItYourselfSection';
import { useMainContentContext } from '@/components/pages/GlobalMainContent';
import CircleBurst from '@/components/ui/CircleBurst';
import FloatingTagPill from '@/components/ui/FloatingTagPill';
import HighlightedText from '@/components/ui/HighlightedText';
import IconButton from '@/components/ui/IconButton';
import ProgressBar from '@/components/ui/ProgressBar';
import TooltipWithIcon, { TooltipPosition } from '@/components/ui/TooltipWithIcon';
import YellowBackgroundBurst from '@/components/ui/YellowBackgroundBurst';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import useSmoothScrollTo from '@/helpers/hooks/useSmoothScrollTo';
import useWindowResize from '@/helpers/hooks/useWindowResize';
import scrollIntoViewWithOffset, { ScrollDirection } from '@/helpers/scrollIntoViewWithOffset';
import cn from '@/lib/cn';
import { Nullable } from '@/types/nullable';

const CAROUSEL_AUTO_PLAY_INTERVAL = 7000;
const USE_CASE_SLIDE_ID_PREFIX = 'use-case-slide';
const companyServicesHighlights = [
    'The latest AI contextual analysis',
    'Trusted market and financial data',
    'Proprietary data pipeline',
    'Institutional-quality portfolio backtesting and benchmarking',
];
const carouselSlides: Array<CarouselSlide> = [
    {
        description: (
            <>
                Supercharge your investment research process and spend less time on process and more on uncovering
                insights, going deep, and honing your advantage. Build, backtest, track strategies, and develop bespoke
                products &ndash; all in one platform.
            </>
        ),
        image: {
            src: '/assets/homepage/hp-slides-assetmanagers.png',
        },
        title: 'Generate alpha and maximize resources of any size team',
        trigger: {
            id: 'asset-portfolio-managers',
            label: 'Asset & portfolio managers ',
            target: '#asset-portfolio-managers',
        },
    },
    {
        description: (
            <>
                Become expert on any company, industry, trend. Quickly focus resources on alpha&#45;generating ideas and
                make them actionable. Accurately and immediately process the impact of news, earnings, and trends on
                your positions.
            </>
        ),
        image: {
            src: '/assets/homepage/hp-slides-hedgefunds.png',
        },
        title: 'Accelerate and identify new opportunities',
        trigger: {
            id: 'hedge-funds',
            label: 'Hedge funds',
            target: '#hedge-funds',
        },
    },
    {
        description: (
            <>
                Quickly understand fundamentals and spend more time on insights, key drivers, and modeling. Find
                accurate answers to your questions just by asking, instead of digging. Quickly build comps, industry
                analysis, and charts, all within your workflow.
            </>
        ),
        image: {
            src: '/assets/homepage/hp-slides-equityresearchers.png',
        },
        title: 'Cast your net deep and wide',
        trigger: {
            id: 'equity-analysts',
            label: 'Equity analysts',
            target: '#equity-analysts',
        },
    },
    {
        description: (
            <>
                Uncover insights while our AI-powered research platform makes sure you don&apos;t miss a thing. Build,
                backtest, track, and develop bespoke strategies - all in one place.
            </>
        ),
        image: {
            src: '/assets/homepage/hp-slides-familyoffices.png',
        },
        title: 'Save time and money while covering more ground',
        trigger: {
            id: 'family-offices',
            label: 'Family offices',
            target: '#family-offices',
        },
    },
    {
        description: (
            <>
                Complete with portfolio analysis, benchmarking, backtesting, and tracking. Develop bespoke products for
                your clients. Seamlessly integrate into your existing workflows with our flexible and powerful API.
            </>
        ),
        image: {
            src: '/assets/homepage/hp-slides-RIAs.png',
        },
        title: 'Build customized portfolios at scale',
        trigger: {
            id: 'rias-and-broker-dealers',
            label: 'RIAs and broker/dealers',
            target: '#rias-and-broker-dealers',
        },
    },
];

interface ImageProps {
    className?: string;
    src: string;
    alt?: string;
    width: number;
    height: number;
}
interface ProductPaneProps {
    className?: string;
    title: string;
    secondaryImage?: ImageProps;
    image: ImageProps;
    imageCaption: string;
    imageCaptionClassName?: string;
    bodyText: React.ReactNode;
    tabIndex?: number;
    linkTo: string;
}

interface HeroSectionProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SectionHeading = styled(Heading)`
    font-family: 'ALT-Riviera-Bold', sans-serif;
`;
const BlueBackgroundBurst = styled.div`
    background: radial-gradient(53.2% 53.2% at 50% 50%, #adc1ff7f 0%, rgba(251, 251, 254, 0) 100%);
`;

const ProductPane = ({
    className,
    title,
    image,
    secondaryImage,
    imageCaption,
    imageCaptionClassName,
    bodyText,
    tabIndex,
    linkTo,
}: ProductPaneProps) => {
    return (
        <Link
            href={linkTo}
            className={cn('group relative', className)}
            tabIndex={tabIndex}
        >
            <BlueBackgroundBurst className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 left-0 z-0 w-full h-full" />
            <div className="relative z-10 h-full flex flex-col justify-start">
                <SectionHeading
                    importance={2}
                    className="text-center text-[48px] leading-[68px] mb-6"
                >
                    <HighlightedText
                        className="text-analyst-black transition-colors duration-300
                    group-focus:bg-gradient-to-r group-focus:from-thematic-purple group-focus:to-thematic-blue group-focus:bg-clip-text group-focus:text-transparent group-hover:bg-gradient-to-r group-hover:from-thematic-purple group-hover:to-thematic-blue group-hover:bg-clip-text group-hover:text-transparent"
                    >
                        {title}
                    </HighlightedText>
                </SectionHeading>

                <div
                    className={cn(
                        'before:from-20% before:rounded-xl before:bg-gradient-to-b before:from-white before:to-thematic-light-blue before:w-full before:h-full before:absolute before:top-0 before:left-0 before:z-10 before:opacity-100 before:transition-all before:duration-300 group-focus:before:opacity-0 group-focus:before:transition-all group-focus:before:duration-300 group-hover:before:opacity-0 group-hover:before:transition-all group-hover:before:duration-300',

                        'after:from-20% after:rounded-xl after:bg-gradient-to-b after:from-white after:to-analyst-lavender after:w-full after:h-full after:absolute after:top-0 after:left-0 after:z-10 after:opacity-0 after:transition-all after:duration-300 group-focus:after:opacity-100 group-focus:after:transition-all group-focus:after:duration-300 group-hover:after:opacity-100 group-hover:after:transition-all group-hover:after:duration-300',
                        'border border-analyst-dark-lavender shadow-md rounded-xl px-5 pt-8 relative z-10',
                        'bg-white group-hover:to-analyst-lavender transition-all duration-300'
                    )}
                >
                    <div className="relative z-20">
                        <Heading
                            importance={3}
                            className={cn('text-center text-[20px] leading-[1.5] mb-6', imageCaptionClassName)}
                        >
                            <HighlightedText
                                className="text-thematic-blue transition-colors duration-300 font-brand-md
                            group-focus:bg-gradient-to-r group-focus:from-thematic-purple group-focus:to-thematic-blue group-focus:bg-clip-text group-focus:text-transparent group-hover:bg-gradient-to-r group-hover:from-thematic-purple group-hover:to-thematic-blue group-hover:bg-clip-text group-hover:text-transparent"
                            >
                                {imageCaption}
                            </HighlightedText>
                        </Heading>

                        <div className="relative">
                            <Image
                                {...image}
                                alt={image.alt || ''}
                            />

                            {secondaryImage && (
                                <Image
                                    {...secondaryImage}
                                    alt={secondaryImage.alt || ''}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="border border-analyst-dark-lavender shadow-md rounded-br-xl rounded-bl-xl px-5 pt-10 pb-6 -translate-y-4 relative z-0 bg-white mx-6 flex-grow">
                    <Paragraph
                        className={cn(
                            'text-analyst-gray mb-0 text-center font-brand-md',
                            'group-focus:text-analyst-black group-hover:text-analyst-black transition-colors duration-300'
                        )}
                    >
                        {bodyText}
                    </Paragraph>
                </div>
            </div>
        </Link>
    );
};

const HeroSection = forwardRef(function HeroSection({ onClick }: HeroSectionProps, ref: Ref<HTMLDivElement>) {
    const { components, eventTypes, locations } = usePosthogTracking();

    return (
        <HeroSectionWrapper
            ref={ref}
            className="sm:pt-0 md:pt-32"
        >
            <section className="flex flex-col items-center flex-grow relative">
                <FloatingTagPill className="gap-2 mb-12 md:mb-8">
                    Say hello to <ThematicPill />
                </FloatingTagPill>

                <SectionHeading
                    importance={1}
                    className="text-center w-full text-3xl leading-12 md:text-5xl max-w-[1000px] lg:text-[55px] lg:leading-[1.2] mb-10 md:mb-32"
                >
                    <HighlightedText>Thematic automates critical portfolio, index, and ETF workflows</HighlightedText>{' '}
                    for institutional asset and crypto managers{' '}
                    <YellowBackgroundBurst>using the latest AI capabilities.</YellowBackgroundBurst>
                </SectionHeading>

                <div className="relative">
                    <div className="flex md:justify-center items-center flex-wrap gap-5 mb-8 relative z-10 w-full md:w-3/4 mx-auto">
                        {companyServicesHighlights.map((highlight) => (
                            <CheckmarkPill
                                key={highlight}
                                className="flex-grow md:flex-grow-0"
                            >
                                {highlight}
                            </CheckmarkPill>
                        ))}
                    </div>

                    <BlueBackgroundBurst className="w-full h-full md:w-[720px] md:h-[720px] flex items-center justify-center absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <IconButton
                            tabIndex={10}
                            iconType="curvedCaret"
                            onClick={onClick}
                            tracking={{
                                eventType: eventTypes.SCROLL_TO_SECTION,
                                trackingProperties: {
                                    component: components.SCROLL_TO_ARROW_BUTTON,
                                    section: locations.HERO_SECTION,
                                },
                            }}
                            iconProps={{
                                iconClassName: 'w-12',
                            }}
                        />
                    </BlueBackgroundBurst>
                </div>
            </section>
        </HeroSectionWrapper>
    );
});

interface ProductOverviewSectionProps {
    className?: string;
}

const ProductOverviewSection = forwardRef(function ProductOverviewSection(
    { className }: ProductOverviewSectionProps,
    ref: Ref<HTMLDivElement>
) {
    return (
        <SectionWrapper
            ref={ref}
            className={cn('min-h-screen relative z-20 flex md:items-center md:justify-center', className)}
        >
            <section className="flex flex-col items-center">
                <FloatingTagPill className="gap-2 mb-14">
                    <ThematicPill /> provides months of investment research in minutes
                </FloatingTagPill>

                <div className="grid grid-cols-12 max-w-7xl mx-auto md:px-8 gap-y-10 md:gap-y-0">
                    <ProductPane
                        tabIndex={11}
                        className="col-span-12 md:col-span-5"
                        imageCaptionClassName=" mb-16"
                        title="Research"
                        image={{
                            height: 250,
                            src: '/assets/homepage/analyst-modules-example.png',
                            width: 600,
                        }}
                        secondaryImage={{
                            className: 'absolute -top-1/2 -left-10 sm:-top-1/4 md:-left-20 z-10',
                            height: 200,
                            src: '/assets/homepage/analyst-prompt-cursor-example.png',
                            width: 600,
                        }}
                        imageCaption="Leverage generative AI to discover alpha"
                        bodyText={
                            <>
                                Generate ideas and develop a P.O.V. &bull; Understand a broad topic or theme &bull;
                                Identify potential investment ideas &bull; Understand a company or industry &bull;
                                Quickly screen for eligibility with key company metrics
                            </>
                        }
                        linkTo="/app/start"
                    />

                    <ProductPane
                        tabIndex={12}
                        className="col-span-12 md:col-start-8 md:col-span-5"
                        imageCaptionClassName=""
                        title="Build"
                        image={{
                            height: 250,
                            src: '/assets/homepage/index-builder-backtesting-example.png',
                            width: 600,
                        }}
                        secondaryImage={{
                            className: 'absolute top-0 -right-10 sm:top-1/4 md:-right-20 z-10',
                            height: 100,
                            src: '/assets/homepage/analyst-backtesting.png',
                            width: 250,
                        }}
                        imageCaption="Supercharge your existing workflow"
                        bodyText={
                            <>
                                Build portfolios and pressure test &bull; Seamless corporate action handling &bull;
                                Instantly calculated portfolio metrics &bull; Backtest over years and situations &bull;
                                Track over time and look for signals
                            </>
                        }
                        linkTo="/index-builder/create"
                    />
                </div>
            </section>
        </SectionWrapper>
    );
});

const AnalystHighlightsSection = () => {
    return (
        <SectionWrapper className="flex flex-col md:items-center mb-16 overflow-hidden">
            <FloatingTagPill className="gap-2 mb-24 relative z-10">
                <ThematicPill /> has unrivaled industry functionality
            </FloatingTagPill>

            <div className="text-center relative z-0 w-full">
                <CircleBurst
                    className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[50%] z-10 w-3/4 h-3/4 lg:to-30% lg:w-[120%] lg:h-[120%]"
                    colorType="thematic-neon-yellow"
                />
                <CircleBurst
                    className="absolute top-1/4 left-0 -translate-x-2/5 -translate-y-1/2 z-10 w-3/4 h-3/4 to-45% lg:to-30% lg:w-[120%] lg:h-[120%]"
                    colorType="analyst-lavender"
                />
                <CircleBurst
                    className="absolute top-1/4 right-0 translate-x-2/5 -translate-y-1/2 z-10 w-3/4 h-3/4 to-45% lg:to-30% lg:w-[100%] lg:h-[100%]"
                    colorType="thematic-light-blue"
                />

                <div className="relative z-20 max-w-4xl mx-auto">
                    <div className="grid grid-cols-12 grid-rows-6 gap-8 items-center justify-center text-center absolute w-full h-full z-30">
                        <TooltipWithIcon
                            iconType="plus"
                            className="col-start-4 justify-self-center"
                            tooltipClassName="left-0 w-[50vw] md:w-[500px]"
                            tooltipPosition={TooltipPosition.TOP_LEFT}
                            defaultOpen={true}
                        >
                            An open prompt structure that auto&#45;recognizes financial filters
                        </TooltipWithIcon>
                        <TooltipWithIcon
                            iconType="plus"
                            className="row-start-2 col-start-9 self-start justify-self-center -translate-y-3/4"
                            tooltipClassName="w-[350px]"
                        >
                            As speedy as it is secure and trustworthy
                        </TooltipWithIcon>
                        <TooltipWithIcon
                            iconType="plus"
                            className="row-start-3 col-start-10 self-start justify-self-center -translate-y-1/2"
                            tooltipClassName="w-[300px]"
                        >
                            Institutional&#45;grade, quality data
                        </TooltipWithIcon>
                        <TooltipWithIcon
                            iconType="plus"
                            className="row-start-4 col-start-4 justify-self-center"
                            tooltipClassName="w-[350px]"
                            tooltipPosition={TooltipPosition.BOTTOM_RIGHT}
                        >
                            Contextual and financial factor analysis
                        </TooltipWithIcon>
                        <TooltipWithIcon
                            iconType="plus"
                            className="row-start-4 col-start-11 justify-self-end  self-end translate-y-full -translate-x-full"
                            tooltipClassName="w-[500px]"
                            tooltipPosition={TooltipPosition.BOTTOM_LEFT}
                        >
                            Multi&#45;agent, AI&#45;optimized for investment management
                        </TooltipWithIcon>
                    </div>
                    <div className="relative z-20 aspect-homepage-laptop w-full">
                        <Image
                            src="/assets/homepage/analyst-laptop-with-shadow.png"
                            alt="Laptop with Analyst highlights"
                            width={900}
                            height={600}
                            className="absolute top-0 left-0 w-full h-full aspect-homepage-laptop"
                        />
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
};

type CarouselTrigger = {
    label: string;
    target: string;
    id: string;
};

interface CarouselSlide {
    title: string;
    description: React.ReactNode;
    image: {
        src: string;
        alt?: string;
    };
    trigger: CarouselTrigger;
}

const carouselTriggers: Array<CarouselTrigger> = carouselSlides.map(({ trigger }) => trigger);

interface UseCaseCarouselTriggersProps {
    triggers: Array<CarouselTrigger>;
    activeTriggerIndex: number;
    updateActiveTriggerIndex: (index: number) => void;
    cancelAutoPlay: () => void;
}

interface UseSlideShowProps {
    autoMode?: boolean;
    numOfSlides: number;
    defaultActiveTriggerIndex?: number;
    timing?: number;
    slides: Array<CarouselSlide>;
    slideWrapperElem: Nullable<HTMLElement>;
    slideCallback?: (activeIndex: number, scrollToEnd?: boolean) => void;
}

const useSlideshow = ({
    autoMode = false,
    defaultActiveTriggerIndex = 0,
    numOfSlides,
    timing = CAROUSEL_AUTO_PLAY_INTERVAL,
    slides = [],
    slideWrapperElem,
    slideCallback,
}: UseSlideShowProps) => {
    const [activeTriggerIndex, setActiveTriggerIndex] = useState(defaultActiveTriggerIndex);
    const [restartAutoPlay, setRestartAutoPlay] = useState<boolean>(true);
    const previousActiveTriggerIndex = usePrevious(activeTriggerIndex);
    const lastTriggerIndex = useMemo(() => numOfSlides - 1, [numOfSlides]);
    const updateActiveTriggerIndex = (index: number) => setActiveTriggerIndex(index);
    const positionSlides = useCallback(
        (currentActiveIndex: number) => {
            const {
                trigger: { id },
            } = slides[currentActiveIndex];
            const slideElem = slideWrapperElem?.querySelector(`#${id}`) as HTMLElement;
            const scrollToEnd = lastTriggerIndex === currentActiveIndex;

            scrollIntoViewWithOffset({
                rootElem: slideWrapperElem,
                scrollDirection: ScrollDirection.HORIZONTAL,
                scrollToElement: slideElem,
                scrollToEnd,
            });

            slideCallback?.(currentActiveIndex, scrollToEnd);
        },
        [slides, slideWrapperElem, lastTriggerIndex, slideCallback]
    );
    const autoPlayIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const cancelAutoPlay = () => {
        setRestartAutoPlay(false);
        autoPlayIntervalRef.current && clearInterval(autoPlayIntervalRef.current as NodeJS.Timeout);
    };
    const startAutoPlay = () => setRestartAutoPlay(true);

    useEffect(() => {
        if (autoMode && restartAutoPlay) {
            autoPlayIntervalRef.current = setInterval(() => {
                setActiveTriggerIndex((prevIndex) => {
                    if (prevIndex === lastTriggerIndex) {
                        return 0;
                    }

                    return prevIndex + 1;
                });
            }, timing);
        }

        return () => {
            if (autoPlayIntervalRef.current) {
                clearInterval(autoPlayIntervalRef.current);
            }
        };
    }, [autoMode, lastTriggerIndex, restartAutoPlay, timing]);

    useEffect(() => {
        if (previousActiveTriggerIndex !== activeTriggerIndex) {
            positionSlides(activeTriggerIndex);
        }
    }, [
        activeTriggerIndex,
        lastTriggerIndex,
        positionSlides,
        previousActiveTriggerIndex,
        slideCallback,
        slideWrapperElem,
        slides,
    ]);

    useWindowResize(() => {
        positionSlides(activeTriggerIndex);
    });

    return {
        activeTriggerIndex,
        cancelAutoPlay,
        startAutoPlay,
        updateActiveTriggerIndex,
    };
};

interface UseCaseCarouselTriggerProps {
    trigger: CarouselTrigger;
    onClick: () => void;
    isActive: boolean;
}

const UseCaseCarouselTrigger = ({ trigger, onClick, isActive }: UseCaseCarouselTriggerProps) => {
    return (
        <li
            key={trigger.label}
            id={trigger.id}
            className={cn('relative grid grid-rows-1 gap-5 transition-all snap-start lg:w-full', {
                'lg:transition-all lg:mb-8': isActive,
            })}
        >
            <Button
                // TODO: TRACKING
                color="transparent"
                type="action"
                onClick={onClick}
                className={cn(
                    'w-full text-left text-base p-0 lg:pl-10 lg:pr-20 leading-[1.5] lg:text-[20px] lg:leading-[1.2] text-analyst-gray font-brand-md hover:text-analyst-purple transition-colors justify-start whitespace-nowrap lg:whitespace-normal',
                    {
                        'text-analyst-black transition-colors': isActive,
                    }
                )}
            >
                {trigger.label}
            </Button>
            <ProgressBar
                autoMode={true}
                autoReset={true}
                smoothProgression={true}
                startProgressBar={isActive}
                resetProgressBar={!isActive}
                timing={CAROUSEL_AUTO_PLAY_INTERVAL}
                mainBarClassName="lg:rounded-tl-none lg:rounded-bl-none"
                className={cn(
                    'translate-y-full lg:translate-y-0 lg:-translate-x-10 opacity-0 transition-opacity absolute w-full top-full lg:static',
                    {
                        'opacity-100 transition-opacity': isActive,
                    }
                )}
                progressBarClassName={cn(isActive && 'duration-5000', 'bg-primary-bg-linear-gradient')}
            />
        </li>
    );
};

const UseCaseCarouselTriggers = forwardRef(function UseCaseCarouselTriggers(
    { triggers, updateActiveTriggerIndex, activeTriggerIndex, cancelAutoPlay }: UseCaseCarouselTriggersProps,
    ref: Ref<HTMLUListElement>
) {
    return (
        <nav className="relative z-30 w-full overflow-hidden">
            <ul
                ref={ref}
                className="w-full overflow-x-auto snap-mandatory snap-x pb-6 lg:snap-none lg:w-auto flex flex-nowrap items-start lg:justify-center gap-8 lg:flex-col lg:gap-4 no-scrollbar lg:overflow-visible"
            >
                {triggers.map((trigger: CarouselTrigger, index: number) => {
                    const isActive = activeTriggerIndex === index;
                    const onTriggerClick = () => {
                        cancelAutoPlay();
                        updateActiveTriggerIndex(index);
                    };

                    return (
                        <UseCaseCarouselTrigger
                            key={trigger.label}
                            trigger={trigger}
                            onClick={onTriggerClick}
                            isActive={isActive}
                        />
                    );
                })}
            </ul>
        </nav>
    );
});

interface UseCaseCarouselSlideProps {
    slide: CarouselSlide;
    className?: string;
    index: number;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
    onTouchStart?: React.TouchEventHandler<HTMLDivElement>;
}

const UseCaseCarouselSlide = ({
    className,
    index,
    onMouseOver,
    onClick,
    onTouchStart,
    slide,
}: UseCaseCarouselSlideProps) => {
    return (
        <div
            id={`${USE_CASE_SLIDE_ID_PREFIX}-${index}`}
            className={cn(
                'flex flex-col items-center md:px-12 lg:py-10 lg:px-12 xl:px-20 2xl:px-24 relative',
                className
            )}
            onMouseOver={onMouseOver}
            onClick={onClick}
            onTouchStart={onTouchStart}
        >
            <div className="border border-analyst-dark-lavender shadow-md rounded-xl pt-5 px-6 pb-10 md:pb-20 lg:px-12 xl:pb-28 bg-white text-thematic-purple relative z-20">
                <Heading
                    importance={3}
                    className="text-center text-[22px] leading-1 mb-6"
                >
                    {slide.title}
                </Heading>
                <Paragraph className="text-analyst-black mb-16 text-center font-brand-md">
                    {slide.description}
                </Paragraph>

                <Image
                    src={slide.image.src}
                    alt="Use case slide"
                    width={1000}
                    height={500}
                    className="-translate-y-3 xl:translate-y-0 relative z-10 md:scale-120 lg:scale-130 2xl:scale-120 border border-analyst-lavender rounded-xl shadow-md w-full origin-center mx-auto"
                />
            </div>
        </div>
    );
};

const UseCasesSection = () => {
    const wrapperRef = useRef<HTMLUListElement>(null);
    const slidesWrapperRef = useRef<HTMLDivElement>(null);
    const moveSlides = (activeIndex: number, scrollToEnd = false) => {
        const slideElem = slidesWrapperRef.current?.querySelector(`#use-case-slide-${activeIndex}`) as HTMLElement;

        scrollIntoViewWithOffset({
            rootElem: slidesWrapperRef.current,
            scrollDirection: ScrollDirection.HORIZONTAL,
            scrollToElement: slideElem,
            scrollToEnd,
        });
    };
    const { activeTriggerIndex, updateActiveTriggerIndex, cancelAutoPlay } = useSlideshow({
        autoMode: true,
        numOfSlides: carouselSlides.length,
        slideCallback: moveSlides,
        slideWrapperElem: wrapperRef.current,
        slides: carouselSlides,
    });

    return (
        <SectionWrapper className="lg:pr-8 lg:pl-0">
            <div className="grid grid-cols-12 gap-8">
                <aside className="col-span-12 lg:col-span-4 flex flex-col items-center gap-y-6 lg:items-start lg:gap-24">
                    <FloatingTagPill className="gap-2 relative z-10 lg:ml-10">
                        <ThematicPill /> is for...
                    </FloatingTagPill>

                    <UseCaseCarouselTriggers
                        ref={wrapperRef}
                        triggers={carouselTriggers}
                        activeTriggerIndex={activeTriggerIndex}
                        updateActiveTriggerIndex={updateActiveTriggerIndex}
                        cancelAutoPlay={cancelAutoPlay}
                    />

                    <Button
                        // TODO: TRACKING
                        color="transparent"
                        type="link"
                        href="/product"
                        roundedCorners="full"
                        className="bg-primary-bg-linear-gradient text-white font-brand-md hover:text-lavender visited:text-white transition-colors hidden lg:block lg:ml-10"
                    >
                        See more about our product
                    </Button>
                </aside>

                <div className="relative text-center col-span-12 lg:col-span-8">
                    <CircleBurst
                        colorType="thematic-pink"
                        className="absolute z-10 top-0 left-0 w-3/4 h-3/4 -translate-y-2/5 -translate-x-2/5 rounded-full"
                    />
                    <div
                        ref={slidesWrapperRef}
                        className="w-full overflow-x-auto snap-mandatory snap-x flex  justify-start flex-nowrap no-scrollbar mb-8 lg:mb-0"
                    >
                        {carouselSlides.map((slide: CarouselSlide, index: number) => {
                            return (
                                <UseCaseCarouselSlide
                                    key={`use-case-slide-${slide.trigger.id}-${index}`}
                                    index={index}
                                    slide={slide}
                                    className={cn(
                                        'w-full flex-shrink-0',
                                        'opacity-100 z-10 transition-all duration-300',
                                        {
                                            'opacity-0 z-0 transition-all duration-500': activeTriggerIndex !== index,
                                        }
                                    )}
                                    onMouseOver={cancelAutoPlay}
                                    onClick={cancelAutoPlay}
                                    onTouchStart={cancelAutoPlay}
                                />
                            );
                        })}
                    </div>

                    <Button
                        // TODO: TRACKING
                        color="transparent"
                        type="link"
                        href="/product"
                        roundedCorners="full"
                        className="bg-primary-bg-linear-gradient text-white font-brand-md hover:text-lavender transition-colors lg:hidden inline-block"
                    >
                        See more about our product
                    </Button>
                </div>
            </div>
        </SectionWrapper>
    );
};

interface CaseStudy {
    className?: string;
    contentWrapperClassName?: string;
    description: React.ReactNode;
    id: string;
    imageFirst?: boolean;
    logo: {
        component: React.FC<React.SVGProps<SVGSVGElement>>;
        className?: string;
    };
    title: React.ReactNode;
    url?: string;
    visual: {
        children?: React.ReactNode;
        image?: {
            src: string;
            alt?: string;
            width: number;
            height: number;
        };
    };
}

const caseStudies: Array<CaseStudy> = [
    {
        description: (
            <>
                <NavigationLink
                    href="https://www.intelligentalpha.co/"
                    target="_blank"
                    className="font-brand-md"
                    title="Intelligent Alpha - Home"
                >
                    Intelligent Alpha
                </NavigationLink>
                , an asset management firm, develops and tracks strategies with an AI- assisted investment committee.
                Over 70 % of their strategies are outpacing their non - AI assisted benchmarks.
            </>
        ),
        id: 'intelligent-alpha',
        logo: {
            component: IALogo,
        },
        title: (
            <>
                Intelligent Alpha is <HighlightedText>at the forefront of embracing AI</HighlightedText> as a
                transformative force in investing
            </>
        ),
        url: '/blog/the-ai-research-analyst ',
        visual: {
            children: (
                <div className="relative pb-[50%] md:pb-[100%] xl:pb-[75%]">
                    <CircleBurst
                        colorType="thematic-neon-yellow"
                        className="absolute bottom-0 right-0 w-full h-full translate-y-1/4 translate-x-1/4 rounded-full to-50% md:to-60% opacity-50"
                    />
                    <CircleBurst
                        colorType="thematic-pink"
                        className="absolute top-1/4 left-1/2 w-full h-full -translate-y-1/2 -translate-x-1/2 rounded-full to-50% md:to-60% opacity-50"
                    />

                    <SpiralIcon className="w-36 md:w-52 absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </div>
            ),
        },
    },
    {
        description: (
            <>
                <NavigationLink
                    href="https://www.theinstitutionalriskanalyst.com/"
                    target="_blank"
                    className="font-brand-md"
                    title="Whalen Global Advisors - The Institutional Risk Analyst"
                >
                    Whalen Global Advisors
                </NavigationLink>
                &nbsp;focuses on the banking, finance and mortgage sectors. With over 3 decades of experience covering
                the industry, their proprietary evaluation framework is the leading scorecard for financial
                institutions.
            </>
        ),
        id: 'wga',
        imageFirst: true,
        logo: {
            component: WGALogo,
        },
        title: (
            <>
                WGA and Chris Whalen Indices track and{' '}
                <HighlightedText>feature the top 10, 25 and 50 publicly traded banks</HighlightedText>
            </>
        ),
        visual: {
            children: (
                <div className="relative pb-[50%] md:pb-[100%] xl:pb-[75%]">
                    <CircleBurst
                        colorType="thematic-light-blue"
                        className="absolute top-1/2 left-1/2 w-full h-full -translate-y-1/2 -translate-x-5/8 rounded-full to-50% md:to-60% opacity-50"
                    />

                    <CirlceEyeIcon className="w-36 md:w-52 absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </div>
            ),
        },
    },
    {
        contentWrapperClassName: 'relative',
        description: (
            <>
                <NavigationLink
                    href="https://www.pinkchip.org/"
                    target="_blank"
                    className="font-brand-md"
                    title="Pink Chip - Home"
                >
                    Pink Chip
                </NavigationLink>
                &nbsp;tracks the performance of companies led by extraordinary women. Built by AKQA, the renowned global
                design agency. In partnership with UN Women and DeGiro.
            </>
        ),
        id: 'pinkchip',
        logo: {
            className: 'bg-analyst-black',
            component: PinkChipLogo,
        },
        title: (
            <>
                <CircleBurst
                    colorType="thematic-neon-yellow"
                    className="absolute bottom-0 left-0 w-full h-full translate-y-1/4 -translate-x-2/5 rounded-full to-50% md:to-60% opacity-30"
                />
                <CircleBurst
                    colorType="thematic-pink"
                    className="absolute top-0 right-0 -translate-y-2/5 translate-x-1/4 w-full h-full rounded-full to-50% md:to-60% opacity-50"
                />
                <span className="relative z-20">
                    PinkChip is out to prove that{' '}
                    <HighlightedText>female&#45;led companies are better for business</HighlightedText>, whether markets
                    like it or not
                </span>
            </>
        ),
        visual: {
            children: (
                <div className="relative pb-[50%] md:pb-[100%] xl:pb-[75%]">
                    <CircleBurst
                        colorType="thematic-light-blue"
                        className="absolute top-1/2 left-1/2 w-full h-full -translate-y-1/2 -translate-x-1/2 rounded-full to-50% md:to-60% opacity-40"
                    />

                    <CurvedBarGraphIcon className="w-36 md:w-52 absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </div>
            ),
        },
    },
];

interface LogoSquareWrapperProps {
    children: React.ReactNode;
    className?: string;
}

const LogoSquareWrapper = ({ children, className }: LogoSquareWrapperProps) => {
    return (
        <div
            className={cn(
                'w-20 h-20 bg-white rounded-lg shadow-md flex items-center justify-center border border-analyst-lavender p-3',
                className
            )}
        >
            {children}
        </div>
    );
};

type HorizontalShowcaseProps = CaseStudy;

const HorizontalShowcase = ({
    className,
    contentWrapperClassName,
    id,
    visual,
    logo,
    imageFirst = false,
    title,
    url,
    description,
}: HorizontalShowcaseProps) => {
    const { component: Logo, className: logoClassName } = logo;

    return (
        <div className={cn('grid grid-cols-8 md:grid-cols-12 gap-10 items-center', className)}>
            <div
                id={id}
                className={cn(
                    'col-span-8 md:col-span-6 flex flex-col items-start order-1',
                    {
                        'md:col-start-7 md:order-2': imageFirst,
                    },
                    contentWrapperClassName
                )}
            >
                <LogoSquareWrapper className={cn('mb-10', logoClassName)}>
                    <Logo className="w-full h-full" />
                </LogoSquareWrapper>
                <Heading
                    importance={3}
                    className="text-analyst-black mb-2"
                >
                    {title}
                </Heading>

                <Paragraph className="md:mb-14 text-analyst-gray relatve z-30">{description}</Paragraph>

                {url && (
                    <Button
                        // TODO: TRACKING
                        type="link"
                        href={url}
                        color="analyst-lavender"
                        openNewWindow={true}
                        roundedCorners="full"
                        className="text-thematic-purple font-brand-md hover:text-analyst-black transition-colors relative z-30"
                    >
                        See the full story
                    </Button>
                )}
            </div>
            <div
                className={cn('col-span-8 md:col-span-6 order-2', {
                    'md:col-start-1 md:row-start-1 md:order-1': imageFirst,
                })}
            >
                {visual.image && (
                    <Image
                        {...visual.image}
                        alt={visual.image.alt || ''}
                    />
                )}

                {visual.children}
            </div>
        </div>
    );
};

const CaseStudiesSection = () => {
    return (
        <SectionWrapper className="overflow-hidden">
            <div className="max-w-7xl mx-auto">
                <header className="flex justify-start mb-20">
                    <FloatingTagPill className="gap-2 relative z-10">
                        What customers are doing with <ThematicPill />
                    </FloatingTagPill>
                </header>

                <div className="flex flex-col gap-10">
                    {caseStudies.map((caseStudy, index: number) => (
                        <HorizontalShowcase
                            key={`case-study-${caseStudy.id}-${index}`}
                            {...caseStudy}
                        />
                    ))}
                </div>
            </div>
        </SectionWrapper>
    );
};

export const Homepage = () => {
    const { updateIntersectionRef } = useMainContentContext();
    const intersectionRef = useRef(null);
    const scrollToSectionRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const heroSectionRef = useRef(null);
    const { onClick } = useSmoothScrollTo({
        scrollToElement: scrollToSectionRef?.current,
    });

    useEffect(() => {
        if (intersectionRef.current) {
            updateIntersectionRef(intersectionRef);
        }
    }, [updateIntersectionRef]);

    return (
        <div ref={scrollContainerRef}>
            <HeroSection
                ref={heroSectionRef}
                onClick={onClick}
            />

            <div
                ref={intersectionRef}
                className="h-[1px] w-full"
            />

            <ProductOverviewSection ref={scrollToSectionRef} />

            <AnalystHighlightsSection />

            <UseCasesSection />

            <CaseStudiesSection />

            <TryItYourselfSection />
        </div>
    );
};
