'use client';

import styled from '@emotion/styled';
import React, { forwardRef, MouseEventHandler, ReactNode, Ref } from 'react';

import cn from '@/lib/cn';

export enum FontFamily {
    AltRiveriaBold,
    BrandBold,
    BrandDefault,
    BrandMedium,
}
const fontFamilyMap = {
    [FontFamily.AltRiveriaBold]: 'font-alt-riveria-bold',
    [FontFamily.BrandBold]: 'font-brand-bold',
    [FontFamily.BrandDefault]: 'font-brand-default',
    [FontFamily.BrandMedium]: 'font-brand-md',
};

type HeadingProps = {
    children: ReactNode;
    importance: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    className?: string;
    id?: string;
    onClick?: MouseEventHandler<Element>;
    fontFamily?: FontFamily;
};

export const Heading = forwardRef(function Heading(
    { children, importance, className, id, onClick, fontFamily = FontFamily.BrandBold }: HeadingProps,
    ref: Ref<HTMLHeadingElement>
) {
    let textSize = 'text-base';
    let lineHeight = '1rem';
    const fontWeight = fontFamilyMap[fontFamily];

    switch (importance) {
        case 0:
            textSize = 'text-6xl'; // 3.75rem = 60px
            lineHeight = 'leading-[4.5rem]'; // 4.5rem = 72px
            break;
        case 1:
            textSize = 'text-5xl'; // 3rem = 48px
            lineHeight = 'leading-[3.5rem]'; // 3.5rem = 56px
            break;
        case 2:
            textSize = 'text-4xl'; // 2.25rem = 36px
            lineHeight = 'leading-[2.75rem]'; // 2.75rem = 44px
            break;
        case 3:
            textSize = 'text-3xl'; // 1.875rem = 30px
            lineHeight = 'leading-9'; // 2.25rem = 36px
            break;
        case 4:
            textSize = 'text-2xl'; // 1.5rem = 24px
            lineHeight = 'leading-8'; // 2rem = 32px
            break;
        case 5:
            textSize = 'text-xl'; // 1.25rem = 20px
            lineHeight = 'leading-7'; // 1.75rem = 28px
            break;
        case 6:
            textSize = 'text-lg'; // 1.125rem = 18px
            lineHeight = 'leading-7'; // 1.75rem = 28px
            break;
    }

    const defaultStyles = `${textSize} ${fontWeight} ${lineHeight}`;

    return React.createElement(
        `h${importance}`,
        { className: cn(defaultStyles, className), id, onClick, ref },
        children
    );
});

type ParagraphProps = {
    children: ReactNode;
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
    className?: string;
};

export const Paragraph = ({ children, className, size = 'base', ...otherProps }: ParagraphProps) => {
    const textSize = `text-${size}`;
    const defaultStyles = 'mb-6';

    return (
        <p
            className={cn(defaultStyles, textSize, className)}
            {...otherProps}
        >
            {children}
        </p>
    );
};

type BaseProps = {
    children: ReactNode;
};

export const Bold = ({ children }: BaseProps) => {
    return <span className="font-brand-bold">{children}</span>;
};

export const Normal = ({ children }: BaseProps) => {
    return <span className="font-brand-default">{children}</span>;
};

export const SectionHeading = styled(Heading)`
    font-family: 'ALT-Riviera-Bold', sans-serif;
`;
