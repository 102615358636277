import { useState } from 'react';

import { OpenWithTypes } from '@/components/forms/login-signup-form';
import Button from '@/components/global/button';
import { LoginSignupModal } from '@/components/modals/login-signup-modal';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { type BaseTrackingProperties } from '@/types/tracking';

interface SignupLoginButtonModalProps {
    className?: string;
    buttonClassName?: string;
    tabIndex?: number;
    trackingProperties?: Omit<BaseTrackingProperties, 'component'>;
}

const SignupLoginButtonModal = ({
    buttonClassName,
    className,
    tabIndex,
    trackingProperties = {} as BaseTrackingProperties,
}: SignupLoginButtonModalProps) => {
    const { categories, components, eventTypes } = usePosthogTracking();
    const [showLoginModal, setShowLoginModalState] = useState<boolean>(false);
    const [modalType, setModalType] = useState<OpenWithTypes>(OpenWithTypes.SIGNUP);

    const openLoginModal = (modalType: OpenWithTypes) => {
        setModalType(modalType);
        setShowLoginModalState(true);
    };
    const closeLoginModal = () => setShowLoginModalState(false);
    const onLoginSuccess = () => {
        closeLoginModal();
        window.location.reload();
    };

    return (
        <div className={className}>
            <div className="flex items-center">
                <Button
                    tabIndex={tabIndex}
                    type="action"
                    onClick={() => openLoginModal(OpenWithTypes.SIGNUP)}
                    color="transparent"
                    className={cn(
                        'rounded-full border border-analyst-gray px-4 py-2 text-analyst-black hover:bg-analyst-lavender transition-colors bg-white bg-opacity-60',
                        buttonClassName
                    )}
                    tracking={{
                        eventType: eventTypes.SIGNUP_LOGIN,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.LOGIN_SIGNUP_MODAL_BUTTON,
                            label: 'Sign up',
                        },
                    }}
                >
                    Sign up
                </Button>
                &nbsp;|&nbsp;
                <Button
                    tabIndex={tabIndex}
                    type="action"
                    onClick={() => openLoginModal(OpenWithTypes.LOGIN)}
                    color="transparent"
                    className={cn(
                        'rounded-full border border-analyst-gray px-4 py-2 text-analyst-black hover:bg-analyst-lavender transition-colors bg-white bg-opacity-60',
                        buttonClassName
                    )}
                    tracking={{
                        eventType: eventTypes.SIGNUP_LOGIN,
                        trackingProperties: {
                            ...trackingProperties,
                            category: categories.LOGIN_SIGNUP,
                            component: components.LOGIN_SIGNUP_MODAL_BUTTON,
                            label: 'Log in',
                        },
                    }}
                >
                    Log in
                </Button>
            </div>
            <LoginSignupModal
                closeFn={closeLoginModal}
                isOpen={showLoginModal}
                openWith={modalType}
                onLoginSuccess={onLoginSuccess}
                trackingProperties={trackingProperties}
            />
        </div>
    );
};

export default SignupLoginButtonModal;
